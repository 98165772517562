import React, { memo } from 'react';

interface IconProps {
  name: string;
  file: string;
  width?: number | string;
  height?: number | string;
  onClick?: () => void;
  viewBox?: string;
}

const Icon: React.FC<IconProps> = ({ name, file, width = 24, height = 24, onClick, viewBox }) => {
  if (!name) {
    return null;
  }
  return (
    <svg width={width} height={height} onClick={onClick} viewBox={viewBox}>
      <use xlinkHref={`/img/${file}.svg#${name}`} />
    </svg>
  );
};

export default memo(Icon);
