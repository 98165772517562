import { useTranslation } from 'next-i18next';
import { FC, useEffect, useState } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import Icon from '../common/Icon';

interface Props {
  isEnabled?: boolean;
}

const SurveyPopup: FC<Props> = ({ isEnabled = true }) => {
  const { t } = useTranslation();
  const [popupActive, setPopupState] = useState(false);
  const cookieValue =
    typeof window !== 'undefined' &&
    document.cookie.split(';').some((item) => item.includes('survey_key=1'));
  const [timeout, setTimer] = useState<any>(0);

  useEffect(() => setTimeout(() => setTimer(true), 10000) as any, []);

  const createCookie = () => {
    document.cookie = `survey_key=1;max-age=604800`;
    setPopupState(!popupActive);
  };

  return (
    <div className="survey">
      {isEnabled && !cookieValue && timeout === true && (
        <div>
          <div className="survey-popup">
            <div className="survey-content">
              <div className="survey-icon">
                <Image src="/img/icons/icon_note.png" width={60} height={60} alt="icon_note" />
              </div>
              <div className="survey-text-container">
                <div className="survey-title">{t('survey_popup_title')}</div>
                <div className="survey-text">{t('survey_popup_text')}</div>
              </div>
            </div>
            <Link href={t('survey_popup_link')}>
              <a className="survey-link" target="_blank">
                {t('survey_popup_link_title')}
              </a>
            </Link>
            <div className="survey-close" onClick={createCookie}>
              <Icon name="16-close" file="sprite" width="16" height="16" />
            </div>
          </div>
          <div
            className={`survey-mobile-popup ${popupActive && '_active'}`}
            onClick={() => setPopupState(!popupActive)}
          >
            <div className="survey-mobile-icon">
              <Image src="/img/icons/icon_note.png" width={33} height={33} alt="icon_note" />
            </div>
            <div className="survey-mobile-title">{t('survey_popup_title')}</div>
            <div className="survey-close survey-mobile-close" onClick={createCookie}>
              <Icon name="16-close" file="sprite" width="16" height="16" />
            </div>
            <div className="survey-full">
              <div className="full-content">
                <div className="full-icon">
                  <Image
                    src="/img/icons/icon_note_mobile.png"
                    width={375}
                    height={160}
                    alt="icon_note_mobile"
                  />
                </div>
                <div className="full-wrap">
                  <div className="full-title">{t('survey_popup_title')}</div>
                  <div className="full-text">{t('survey_popup_text')}</div>
                  <Link href={t('survey_popup_link')}>
                    <a className="survey-link" target="_blank">
                      {t('survey_popup_link_title')}
                    </a>
                  </Link>
                </div>
              </div>
              <div className="full-close" onClick={createCookie}>
                <Icon name="16-close" file="sprite" width="16" height="16" />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SurveyPopup;
